import actions from '../actions/websiteActions';
import { getActionError } from "../../utils/reducerUtils";

export const initialState = {
  isLoading: false,
  websites: [],
  website: {},
  errors: {},
  redirectTo: null
}
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.API_GET_WEBSITE_PENDING:
      return {
        ...state,
        errors: {},
        website: {},
        isLoading: true

      };
    case actions.API_GET_WEBSITE_SUCCESS:
      return {
        ...state,
        errors: {},
        isLoading: false,
        website: action.payload

      };
    case actions.API_GET_WEBSITE_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: getActionError(action)

      };

    case actions.API_GET_WEBSITES_PENDING:
      return {
        ...state,
        errors: {},
        websites: [],
        isLoading: true

      };
    case actions.API_GET_WEBSITES_SUCCESS:
      return {
        ...state,
        errors: {},
        isLoading: false,
        websites: action.payload

      };
    case actions.API_GET_WEBSITES_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: getActionError(action)
      };

    case actions.API_POST_WEBSITE_PENDING:
      return {
        ...state,
        errors: {},
        isLoading: true,
        redirectTo: null
      };
    case actions.API_POST_WEBSITE_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const websiteIndex = state.websites.findIndex(wb => wb.uuid === action.payload.uuid);
      // eslint-disable-next-line no-case-declarations
      const _websites = websiteIndex === -1 ? [...state.websites, action.payload] : [...state.websites.slice(0, websiteIndex), action.payload, ...state.websites.slice(websiteIndex + 1)];
      return {
        ...state,
        isLoading: false,
        websites: _websites,
        website: action.payload,
        redirectTo: '/websites'

      };
    case actions.API_POST_WEBSITE_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: getActionError(action),
        redirectTo: null
      }
    case actions.RESET_REDIRECT_TO:
      return {
        ...state,
        redirectTo: null
      }
    case actions.RESET: {
      return initialState
    }
    default:
      return state;
  }
}
