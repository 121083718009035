import { axiosInstance, axiosPromise } from "../../services/axios";
import { getServerUrl } from "../../services/utils";
import { actionCreator } from "../../utils/reducerUtils";

const API_POST_WEBSITE_PENDING = 'api_post_website_pending';
const API_POST_WEBSITE_SUCCESS = 'api_post_website_success';
const API_POST_WEBSITE_FAILED = 'api_post_website_failed';

const API_GET_WEBSITE_PENDING = 'api_get_website_pending';
const API_GET_WEBSITE_SUCCESS = 'api_get_website_success';
const API_GET_WEBSITE_FAILED = 'api_get_website_failed';

const API_GET_WEBSITES_PENDING = 'api_get_websites_pending';
const API_GET_WEBSITES_SUCCESS = 'api_get_websites_success';
const API_GET_WEBSITES_FAILED = 'api_get_websites_failed';

const RESET_REDIRECT_TO = 'website_redirect_to';

const RESET = 'reset';

const websiteActions = {
  API_POST_WEBSITE_PENDING,
  API_POST_WEBSITE_SUCCESS,
  API_POST_WEBSITE_FAILED,

  API_GET_WEBSITE_PENDING,
  API_GET_WEBSITE_SUCCESS,
  API_GET_WEBSITE_FAILED,

  API_GET_WEBSITES_PENDING,
  API_GET_WEBSITES_SUCCESS,
  API_GET_WEBSITES_FAILED,

  RESET_REDIRECT_TO,
  
  RESET
}

export default websiteActions;

export const fetchWebsite = uuid => async dispatch => {
  try {
    dispatch(actionCreator(API_GET_WEBSITE_PENDING));
    const payload = await axiosPromise(axiosInstance.get(`${getServerUrl()}/admin/website?uuid=${uuid}`));
    dispatch(actionCreator(API_GET_WEBSITE_SUCCESS, payload[0]));
  } catch (e) {
    dispatch(actionCreator(API_GET_WEBSITE_FAILED, e))
  }
}

export const submitWebsite = data => async dispatch => {
  try {
    dispatch(actionCreator(API_POST_WEBSITE_PENDING));
    const payload = await axiosPromise(axiosInstance.post(`${getServerUrl()}/admin/website`, data));
    dispatch(actionCreator(API_POST_WEBSITE_SUCCESS, payload));
  } catch (e) {
    dispatch(actionCreator(API_POST_WEBSITE_FAILED, e))
  }
}

export const fetchWebsites = () => async dispatch => {
  try {
    dispatch(actionCreator(API_GET_WEBSITES_PENDING));
    const payload = await axiosPromise(axiosInstance.get(`${getServerUrl()}/admin/website`));

    dispatch(actionCreator(API_GET_WEBSITES_SUCCESS, payload));
  } catch (e) {
    dispatch(actionCreator(API_GET_WEBSITES_FAILED, e))
  }
}

export const resetRedirect = () => dispatch => dispatch(actionCreator(RESET_REDIRECT_TO))
