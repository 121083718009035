export const getActionError = action => {
  return action
    ? {
        status: action.payload.status,
        message: action.payload.message,
        errors: action.payload?.errors
      }
    : {
        status: 500,
        message: 'Server Error, please try later'
      }
}

export const actionCreator = (type = '', payload = {}) => ({ type, payload })
