import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { canAccessArticles } from '../utils/userUtils';
import { useAuthState } from '../hooks/useAuth';
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

const ArticleListItem = props => {
  const [deleteDropdownOpen, setDeleteDropdownOpen] = useState(false);
  const toggleDeleteDropdown = () => setDeleteDropdownOpen(!deleteDropdownOpen);

  const { loggedInUser } = useAuthState();
  const { article: { title, uuid, published, blurb, publishAt, url }, website: { uuid: websiteUUID, url: websiteUrl, folder }, handleDelete } = props;
  const canAccess = canAccessArticles(loggedInUser);
  return (
      <div className="mb-2 p-3 border rounded">
        <div className="d-flex justify-content-between ">
          <h3 className="m-0">
            {title}
          </h3>
          <div>
            {canAccess && !published && (
                <ButtonDropdown isOpen={deleteDropdownOpen} toggle={toggleDeleteDropdown} className="me-2">
                  <DropdownToggle caret color="danger" outline>
                    DELETE
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => handleDelete(uuid)} className={'me-2'}>CONFIRM</DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
            )}
            {published && (
                <a className="btn btn-outline-secondary me-2" target="_blank" href={`https://${websiteUrl}/${folder}/${url}`} rel="noreferrer">VIEW ONLINE</a>
            )}
            {
              canAccess && <Link to={`/websites/${websiteUUID}/articles/${uuid}/edit`} className='btn btn-outline-primary'>
                UPDATE
              </Link>
            }
          </div>
        </div>

        <p className="small m-0 fst-italic">{new Date(publishAt).toUTCString()}</p>
        <p className="m-0">
          {blurb}
        </p>
      </div>

  );
};

export default ArticleListItem;
