import { axiosInstance, axiosPromise } from "../../services/axios";
import { getServerUrl } from "../../services/utils";
import { actionCreator } from "../../utils/reducerUtils";

const API_POST_ARTICLE_PENDING = 'api_post_article_pending';
const API_POST_ARTICLE_SUCCESS = 'api_post_article_success';
const API_POST_ARTICLE_FAILED = 'api_post_article_failed';
const RESET_REDIRECT_TO = 'article_redirect_to';

const REQUEST_ARTICLES = 'request_articles';
const REQUEST_ARTICLES_ARTICLE_SUCCESS = 'request_articles_article_success';
const REQUEST_ARTICLES_ARTICLE_FAILED = 'request_articles_article_failed';

const REQUEST_ARTICLE = 'request_article';
const REQUEST_ARTICLE_ARTICLE_SUCCESS = 'request_article_article_success';
const REQUEST_ARTICLE_ARTICLE_FAILED = 'request_article_article_failed';

const REQUEST_YEARS = 'request_years';
const REQUEST_YEARS_SUCCESS = 'request_years_success';
const REQUEST_YEARS_FAILED = 'request_years_failed';

const DELETE_ARTICLE = 'delete_article';
const DELETE_ARTICLE_SUCCESS = 'delete_article_success';
const DELETE_ARTICLE_FAILED = 'delete_article_failed';

const RESET = 'reset';

const articleActions = {
  API_POST_ARTICLE_PENDING,
  API_POST_ARTICLE_SUCCESS,
  API_POST_ARTICLE_FAILED,

  REQUEST_ARTICLE,
  REQUEST_ARTICLE_ARTICLE_SUCCESS,
  REQUEST_ARTICLE_ARTICLE_FAILED,

  REQUEST_ARTICLES,
  REQUEST_ARTICLES_ARTICLE_SUCCESS,
  REQUEST_ARTICLES_ARTICLE_FAILED,

  REQUEST_YEARS,
  REQUEST_YEARS_SUCCESS,
  REQUEST_YEARS_FAILED,

  DELETE_ARTICLE,
  DELETE_ARTICLE_SUCCESS,
  DELETE_ARTICLE_FAILED,

  RESET_REDIRECT_TO,

  RESET
}

export default articleActions;

export const submitArticle = data => async dispatch => {
  try {
    dispatch(actionCreator(API_POST_ARTICLE_PENDING));
    const payload = await axiosPromise(axiosInstance.post(`${getServerUrl()}/admin/article`, data));
    dispatch(actionCreator(API_POST_ARTICLE_SUCCESS, payload));
  } catch (e) {
    dispatch(actionCreator(API_POST_ARTICLE_FAILED, e))
  }
}

export const fetchArticleById = uuid => async dispatch => {
  try {
    dispatch(actionCreator(REQUEST_ARTICLE));
    const payload = await axiosPromise(axiosInstance.get(`${getServerUrl()}/admin/article/?uuid=${uuid}`));
    dispatch(actionCreator(REQUEST_ARTICLE_ARTICLE_SUCCESS, payload[0]));
  } catch (e) {
    dispatch(actionCreator(REQUEST_ARTICLE_ARTICLE_FAILED, e))
  }
}

export const fetchAllArticles = () => async dispatch => {
  try {
    dispatch(actionCreator(REQUEST_ARTICLES));
    const payload = await axiosPromise(axiosInstance.get(
            `${getServerUrl()}/admin/article`
    ))
    dispatch(actionCreator(REQUEST_ARTICLES_ARTICLE_SUCCESS, payload[0]));
  } catch (e) {
    dispatch(actionCreator(REQUEST_ARTICLES_ARTICLE_FAILED, e))
  }
}

export const fetchArticlesFromYear = (id, year) => async dispatch => {
  try {
    dispatch(actionCreator(REQUEST_ARTICLES));
    const payload = await axiosPromise(axiosInstance.get(
            `${getServerUrl()}/api/get-articles?website_uuid=${id}&year=${year}`
    ))
    dispatch(actionCreator(REQUEST_ARTICLES_ARTICLE_SUCCESS, payload));
  } catch (e) {
    dispatch(actionCreator(REQUEST_ARTICLES_ARTICLE_FAILED, e))
  }
}

export const fetchArticleYears = (uuid) => async dispatch => {
  try {
    dispatch(actionCreator(REQUEST_YEARS));
    const payload = await axiosPromise(axiosInstance.get(
            `${getServerUrl()}/api/get-years?website_uuid=${uuid}`
    ))
    dispatch(actionCreator(REQUEST_YEARS_SUCCESS, payload));
  } catch (e) {
    dispatch(actionCreator(REQUEST_YEARS_FAILED, e))
  }
}

export const fetchArticlesFromWebsiteId = uuid => async dispatch => {
  try {
    dispatch(actionCreator(REQUEST_ARTICLES));
    const payload = await axiosPromise(axiosInstance.get(
        `${getServerUrl()}/admin/article?website_uuid=${uuid}`
    ))
    dispatch(actionCreator(REQUEST_ARTICLES_ARTICLE_SUCCESS, payload));
  } catch (e) {
    dispatch(actionCreator(REQUEST_ARTICLES_ARTICLE_FAILED, e))
  }
}

export const deleteArticle = (uuid) => async dispatch => {
  try {
    dispatch(actionCreator(DELETE_ARTICLE));
    const payload = await axiosPromise(axiosInstance.delete(`${getServerUrl()}/admin/article/delete?uuid=${uuid}`))
    dispatch(actionCreator(DELETE_ARTICLE_SUCCESS, payload));
  } catch (e) {
    dispatch(actionCreator(DELETE_ARTICLE_FAILED, e))
  }
}

export const resetRedirect = () => dispatch => dispatch(actionCreator(RESET_REDIRECT_TO))
