import { useContext } from 'react'
import { MessageContext } from '../contexts/message'

export const useMessage = () => {
  const {
    message,
    addMessage,
    handleAPIError,
    clearMessage
  } = useContext(MessageContext)

  return {
    message,
    addMessage,
    handleAPIError,
    clearMessage
  }
}
