import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuthState } from "../hooks/useAuth";

const ProtectedRouter = ({ children, ...rest }) => {
  const { loggedInUser } = useAuthState();
  return (
    <Route
      {...rest}
      render={() =>
        loggedInUser !== null ? children : <Redirect to="/login" />
      }
    />
  );
};

export default ProtectedRouter;
