import React, { createContext, useReducer } from "react";
import { initialState, reducer } from "./reducers/authReducer";
import * as actions from "./actions/authActions";
import { useErrorMessage } from "../hooks/useErrorMessage";

export const AuthStateContext = createContext();
export const AuthDispatchContext = createContext();

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useErrorMessage(state.errors)

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={{ dispatch, actions }}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};
