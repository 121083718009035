import { useEffect } from 'react'
import { useArticleDispatch } from './useArticles'
import { useWebsiteDispatch } from './useWebsites'
import { useAuthDispatch } from './useAuth'
import { getUserFromLocalStorage } from '../utils/localStorage'
import articleActions from '../contexts/actions/articleActions'
import websiteActions from '../contexts/actions/websiteActions'

export const useRedirectIfUnauthorized = () => {
  const { dispatch: websiteDispatch } = useWebsiteDispatch()
  const { dispatch: articleDispatch } = useArticleDispatch()
  const { actions: { logout }, dispatch } = useAuthDispatch()

  const onChangeLocalStorage = () => {
    const user = getUserFromLocalStorage()

    if (user === null) {
      websiteDispatch({ type: websiteActions.RESET })
      articleDispatch({ type: articleActions.RESET })
      logout()(dispatch)
    }
  }

  useEffect(() => {
    const listener = window.addEventListener('storage', onChangeLocalStorage);

    return () => window.removeEventListener(listener)
  }, [])
}
