import { useEffect } from "react";
import { useMessage } from "./useMessage";
import _ from 'lodash';

export const useErrorMessage = error => {
  const { handleAPIError } = useMessage();

  const message = !_.isEmpty(error) && !_.isString(error) ? error.message : null

  useEffect(() => {
    if (message) {
      handleAPIError(message)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message])
}
