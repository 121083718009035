import React, { createContext, useReducer } from "react";
import { fetchWebsite, submitWebsite, fetchWebsites, resetRedirect } from './actions/websiteActions';
import { reducer, initialState } from "./reducers/websiteReducer";

export const WebsiteStateContext = createContext();
export const WebsiteDispatchContext = createContext();

export const WebsitesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
        <WebsiteStateContext.Provider value={state}>
            <WebsiteDispatchContext.Provider value={{
              dispatch,
              actions: {
                fetchWebsite,
                submitWebsite,
                fetchWebsites,
                resetRedirect
              }
            }}
            >
                {children}
            </WebsiteDispatchContext.Provider>
        </WebsiteStateContext.Provider>
  );
}
