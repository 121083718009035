import actions from '../actions/articleActions';
import { getActionError } from "../../utils/reducerUtils";

export const initialState = {
  isLoading: false,
  article: {},
  articles: [],
  errors: {},
  years: [],
  redirectTo: null
}
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    // Submit
    case actions.API_POST_ARTICLE_PENDING:
      return {
        ...state,
        errors: {},
        website: {},
        isLoading: true,
        redirectTo: null

      };
    case actions.API_POST_ARTICLE_SUCCESS:
      return {
        ...state,
        errors: {},
        isLoading: false,
        article: action.payload,
        redirectTo: `/websites/${action.payload.website_uuid}`

      };
    case actions.API_POST_ARTICLE_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: getActionError(action)

      };
      // Get article
    case actions.REQUEST_ARTICLE:
      return { ...state, errors: {}, isLoading: true };
    case actions.REQUEST_ARTICLE_ARTICLE_SUCCESS:
      return { ...state, errors: {}, isLoading: false, article: action.payload };
    case actions.REQUEST_ARTICLE_ARTICLE_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: getActionError(action)
      };

      // Get article Years
    case actions.REQUEST_YEARS:
      return { ...state, errors: {}, isLoading: true, years: [] };
    case actions.REQUEST_YEARS_SUCCESS:
      return { ...state, errors: {}, isLoading: false, years: action.payload };
    case actions.REQUEST_YEARS_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: getActionError(action)
      };

      // Delete article
    case actions.DELETE_ARTICLE:
      return { ...state, errors: {}, isLoading: true };
    case actions.DELETE_ARTICLE_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const articleIndex = state.articles.findIndex(article => article.uuid === action.payload.uuid);
      // eslint-disable-next-line no-case-declarations
      const article = state.articles[articleIndex];
      // eslint-disable-next-line no-case-declarations
      const articles = articleIndex === -1 ? state.articles : [...state.articles.slice(0, articleIndex), ...state.articles.slice(articleIndex + 1)];
      return { ...state, errors: {}, isLoading: false, redirectTo: `/website/${article.website_uuid}/${new Date().getFullYear()}`, articles };
    case actions.DELETE_ARTICLE_FAILED:
      return { ...state, isLoading: false, errors: getActionError(action) };

      // Get articles
    case actions.REQUEST_ARTICLES:
      return { ...state, errors: {}, isLoading: true, articles: [] };
    case actions.REQUEST_ARTICLES_ARTICLE_SUCCESS:

      return { ...state, errors: {}, isLoading: false, articles: action.payload };
    case actions.REQUEST_ARTICLES_ARTICLE_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: getActionError(action)
      };
    case actions.RESET_REDIRECT_TO:
      return {
        ...state,
        redirectTo: null
      }
    case actions.RESET:
      return initialState
    default:
      return state;
  }
}
