import React, { useEffect, useState } from "react";

const styles = {
  countdownContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    margin: "auto"
  },
  svg: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    transform: "rotateY(-180deg) rotateZ(-90deg)",
    overflow: "visible"
  }
};
const CountdownTimer = ({ size, seconds, strokeBgColor, strokeWidth, strokeColor, onFinish }) => {
  const radius = size / 2
  const circumference = size * Math.PI;
  const milliseconds = seconds * 1000;
  const [timeLeft, setTimeLeft] = useState(milliseconds);

  useEffect(() => {
    if (timeLeft <= 0) {
      if (typeof onFinish === "function") {
        onFinish()
      }
      return;
    }
    const interval = setInterval(() => {
      setTimeLeft(timeLeft - 10);
    }, 10);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  const strokeDashoffset = () => circumference - (timeLeft / milliseconds) * circumference

  const countdownSizeStyles = {
    height: size,
    width: size
  };
  const textStyles = {
    color: strokeColor,
    fontSize: size * 0.3,
    marginBottom: '5px'
  };

  const _seconds = (timeLeft / 1000).toFixed();

  return <div
        style={{ ...styles.countdownContainer, ...countdownSizeStyles }}
  >
        <p style={textStyles}>{_seconds}s</p>
        <svg style={styles.svg}>
            <circle
                cx={radius}
                cy={radius}
                r={radius}
                fill="none"
                stroke={strokeBgColor}
                strokeWidth={strokeWidth}
            />
        </svg>
        <svg style={styles.svg}>
            <circle
                strokeDasharray={circumference}
                strokeDashoffset={strokeDashoffset()}
                r={radius}
                cx={radius}
                cy={radius}
                fill="none"
                strokeLinecap="round"
                stroke={strokeColor}
                strokeWidth={strokeWidth}
            />
        </svg>
    </div>
}
export default CountdownTimer
