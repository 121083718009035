import React, {
  createContext,
  useState
} from 'react';

export const MessageContext = createContext({
  message: null,
  addMessage: (_) => {
  },
  handleAPIError: (_) => {
  },
  clearMessage: () => {}
});

export const MessageProvider = ({ children }) => {
  const [message, setMessage] = useState(null);

  const contextValue = {
    message,
    addMessage: (message) => setMessage({ type: 'SUCCESS', content: message }),
    handleAPIError: (message) => setMessage({
      type: 'ERROR',
      content: message
    }),
    clearMessage: () => setMessage(null)
  };

  return (
      <MessageContext.Provider value={contextValue}>
        {children}
      </MessageContext.Provider>
  );
};
