import axios from 'axios';
import { getToken, clearUserFromLocalStorage } from '../utils/localStorage'

export const handleAxiosResponse = (res) => {
  if (res.status >= 200 && res.status <= 300) {
    return res.data
  }

  if (res.status === 401) {
    clearUserFromLocalStorage();
    window.dispatchEvent(new Event('storage'))
  };

  throw res.data;
}

export const axiosPromise = async (promise) => {
  return await promise.then((res) => handleAxiosResponse(res))
}

export const axiosInstance = axios.create({
  validateStatus: (status) => status !== 500
})

axiosInstance.interceptors.request.use(function (config) {
  const token = getToken();

  config.headers.Authorization = token ? `JWT ${token}` : '';
  return config;
})
