import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { useWebsiteDispatch, useWebsiteState } from '../hooks/useWebsites';
import { useArticleDispatch, useArticleState } from '../hooks/useArticles';
import { useErrorMessage } from '../hooks/useErrorMessage';
import { Breadcrumb } from '../components/BreadcrumbsContainer';
import ArticleListItem from '../components/ArticleListItem';
import { useAuthState } from "../hooks/useAuth";
import { canAccessArticles, canAccessWebsites } from "../utils/userUtils";
import _ from 'lodash';

const WebsiteDetails = () => {
  const { uuid } = useParams();
  const { dispatch, actions: { fetchWebsite } } = useWebsiteDispatch();
  const { errors: websiteErrors, website: { uuid: loadedUUID, name, url, folder }, website } = useWebsiteState();
  const { articles, errors: articleErrors } = useArticleState();
  const { dispatch: articleDispatch, actions: { fetchArticlesFromWebsiteId, deleteArticle } } = useArticleDispatch();
  const { loggedInUser } = useAuthState();

  useEffect(() => {
    fetchWebsite(uuid)(dispatch);
    fetchArticlesFromWebsiteId(uuid)(articleDispatch);
  }, [articleDispatch, dispatch, fetchArticlesFromWebsiteId, fetchWebsite, uuid]);

  useErrorMessage(websiteErrors || articleErrors);

  if (!loadedUUID && _.isEmpty(websiteErrors)) {
    return <>
          <Breadcrumb to="/websites">Websites</Breadcrumb>
          Loading...
        </>;
  }

  const handleDelete = uuid => deleteArticle(uuid)(articleDispatch);
  return (
          <>
            <Breadcrumb to="/websites">Websites</Breadcrumb>
            <Breadcrumb>Details of {name}</Breadcrumb>

            <div className="d-flex justify-content-between">
              <h1>
                {name}
              </h1>
              <div>
                  {
                      canAccessWebsites(loggedInUser) && <Link to={`/websites/${uuid}/edit`} className="me-2">
                          <Button color='primary' outline>UPDATE</Button>
                      </Link>
                  }
                <a className="btn btn-outline-secondary" target="_blank" href={`https://${url}/${folder}`} rel="noreferrer">VIEW ONLINE</a>
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <h2>Articles</h2>
                {
                    canAccessArticles(loggedInUser) && <Link to={`/websites/${uuid}/articles/add`}>
                        <Button color='success' outline>+ ADD NEW ARTICLE</Button>
                    </Link>
                }
            </div>

            {(!articles || !articles.length) && <div className="alert alert-info">No articles found</div>}

            {articles && articles.length > 0 && articles.map((article) => (
                <ArticleListItem key={article.uuid} article={article} website={website} handleDelete={handleDelete}/>
            ))}
          </>
  );
}
;

export default WebsiteDetails;
