import { useContext } from "react";
import { ArticleDispatchContext, ArticleStateContext } from "../contexts/articles";

export const useArticleState = () => {
  const context = useContext(ArticleStateContext);
  if (context === undefined) {
    throw new Error("useArticleState must be used within a AuthProvider");
  }

  return context;
}

export const useArticleDispatch = () => {
  const context = useContext(ArticleDispatchContext);
  if (context === undefined) {
    throw new Error("useArticleDispatch must be used within a AuthProvider");
  }

  return context;
}
