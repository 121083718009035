import _ from 'lodash';

export const SECTIONS = {
  WEBSITE: 'website',
  ARTICLE: 'article'
};

export const ROLES = {
  ADMIN: 'admin',
  USER: 'user'
};

const isSuperAdmin = (user) => _.get(user, 'isSuperAdmin', false);

const isOverallAdmin = (user) => _.get(user, 'roles.isOverallAdmin', false);

const isOverallUser = (user) => _.get(user, 'roles.isOverallUser', false);

export const canAccess = (user, role, section) => isSuperAdmin(user) ||
    isOverallAdmin(user) ||
    (role === 'user' && isOverallUser(user)) ||
    _.get(user, `roles.sections.${section}.role`) === role;

export const canAccessArticles = user => canAccess(user, ROLES.ADMIN, SECTIONS.ARTICLE);
export const canAccessWebsites = user => canAccess(user, ROLES.ADMIN, SECTIONS.WEBSITE);
