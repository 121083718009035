import { useContext } from "react";
import { WebsiteDispatchContext, WebsiteStateContext } from "../contexts/websites";

export const useWebsiteState = () => {
  const context = useContext(WebsiteStateContext);
  if (context === undefined) {
    throw new Error("useWebsiteState must be used within a AuthProvider");
  }

  return context;
}

export const useWebsiteDispatch = () => {
  const context = useContext(WebsiteDispatchContext);
  if (context === undefined) {
    throw new Error("useWebsiteDispatch must be used within a AuthProvider");
  }

  return context;
}
