import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useBreadcrumbContext } from '../contexts/breadcrumb';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

const BreadcrumbsContainer = () => {
  const portalNodeRef = useRef();
  const [, setPortalNode] = useBreadcrumbContext();

  useEffect(() => {
    setPortalNode(portalNodeRef.current);
  }, [setPortalNode]);

  return (
      <div className="bg-light p-3 mb-3">
        <nav aria-label="breadcrumb">
          <Container>
            <ol className="breadcrumb m-0" ref={portalNodeRef}/>
          </Container>
        </nav>
      </div>
  );
};

export const Breadcrumb = ({ children, to, ...props }) => {
  const [portalNode] = useBreadcrumbContext();

  return portalNode
    ? ReactDOM.createPortal(
          <li {...props} className="breadcrumb-item">
            {
              to
                ? <Link className="text-decoration-none" to={to}>{children}</Link>
                : <>{ children }</>
            }
          </li>,
          portalNode
    )
    : null;
};

export default BreadcrumbsContainer;
