import React from "react";
import { Alert } from "reactstrap";
import CountdownTimer from "./CoundownTimer";
import './MessageCard.css'

const MessageCard = ({ message: { content, type }, handleDismiss }) => {
  const color = type === 'ERROR' ? '#842029' : '#28875a';
  const bgColor = type === 'ERROR' ? '#f8d7da' : '#d1e7dd'
  return (<div className={'messages'}>
            <Alert color={type === 'ERROR' ? 'danger' : 'success'} className='text-center' onClick={handleDismiss}>
                {content}
                <div className="countdown">
                    <CountdownTimer
                        seconds={5}
                        size={40}
                        strokeBgColor={bgColor}
                        strokeColor={color}
                        strokeWidth={3.5}
                        onFinish={handleDismiss}
                    />
                </div>
                <div className="hover-only">
                    Click to dismiss
                </div>

            </Alert>
        </div>
  );
};
export default MessageCard
