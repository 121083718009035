import { actionCreator } from "../../utils/reducerUtils";
import { axiosInstance, axiosPromise } from "../../services/axios";
import { getServerUrl } from "../../services/utils";
import { clearUserFromLocalStorage, persistUserToLocalStorage } from "../../utils/localStorage";

const LOGIN_PENDING = 'login_pending';
const LOGIN_SUCCESS = 'login_success';
const LOGIN_FAILED = 'login_failed';
const LOGOUT = 'logout';
const RESET_REDIRECT_TO = 'login_redirect_to';

const authActions = {
  LOGIN_PENDING,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT,
  RESET_REDIRECT_TO
};

export default authActions;

export const login = data => async dispatch => {
  try {
    dispatch(actionCreator(LOGIN_PENDING));
    const payload = await axiosPromise(axiosInstance.post(`${getServerUrl()}/admin/login`, data));
    persistUserToLocalStorage(payload);
    dispatch(actionCreator(LOGIN_SUCCESS, payload));
  } catch (e) {
    dispatch(actionCreator(LOGIN_FAILED, e))
  }
}

export const logout = () => async dispatch => {
  clearUserFromLocalStorage()
  dispatch(actionCreator(LOGOUT))
}

export const resetRedirect = () => dispatch => dispatch(actionCreator(RESET_REDIRECT_TO))
