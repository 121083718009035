import React from 'react';
import { useMessage } from '../hooks/useMessage';
import { Container } from 'reactstrap';
import './MessageCard.css'
import MessageCard from "./MessageCard";

export const MessageContainer = () => {
  const { message, clearMessage } = useMessage();

  return (
        <Container onClick={clearMessage}>
            {message !== null && (
                <MessageCard message={message} handleDismiss={clearMessage}/>
            )}
        </Container>
  );
};

export default MessageContainer;
