import React, { useContext, useState } from 'react';

const Context = React.createContext();

export const useBreadcrumbContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error('Missing BreadcrumbProvider.');
  }

  return context;
};

export const BreadcrumbProvider = ({ children }) => {
  const portalNodeState = useState();

  return (
      <Context.Provider value={portalNodeState}>{children}</Context.Provider>
  );
};
