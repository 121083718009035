export const persistToLocalStorage = (name) => (data) => localStorage.setItem(name, JSON.stringify(data));

export const persistUserToLocalStorage = persistToLocalStorage("loggedInUser");

export const getFromLocalStorage = (name) =>
  JSON.parse(localStorage.getItem(name));

export const getUserFromLocalStorage = () =>
  getFromLocalStorage("loggedInUser");

export const clearFromLocalStorage = (name) =>
  window.localStorage.removeItem(name);

export const clearUserFromLocalStorage = () =>
  clearFromLocalStorage("loggedInUser");

export const getToken = () => {
  const storedUser = getUserFromLocalStorage();
  const user = storedUser?.auth?.filter((auth) => auth.type === 'jwt');
  if (!user) {
    return ''
  }
  const { auth } = user[0]
  return auth
}
