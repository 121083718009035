import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { AuthProvider } from './contexts/auth';
import { WebsitesProvider } from './contexts/websites';
import { ArticlesProvider } from './contexts/articles';
import { MessageProvider } from './contexts/message';
import { HashRouter } from 'react-router-dom';
import { BreadcrumbProvider } from './contexts/breadcrumb';

ReactDOM.render(
    <React.StrictMode>
      <MessageProvider>
        <AuthProvider>
          <WebsitesProvider>
            <ArticlesProvider>
              <BreadcrumbProvider>
                <HashRouter>
                  <App/>
                </HashRouter>
              </BreadcrumbProvider>
            </ArticlesProvider>
          </WebsitesProvider>
        </AuthProvider>
      </MessageProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
