import actions from '../actions/authActions';
import { getActionError } from "../../utils/reducerUtils";
import { getUserFromLocalStorage } from "../../utils/localStorage";

const user = getUserFromLocalStorage();

export const initialState = {
  loggedInUser: user || null,
  isLoading: false,
  errors: {},
  redirectTo: null
}
export const reducer = (state = { initialState }, action) => {
  switch (action.type) {
    case actions.LOGIN_PENDING:
      return {
        ...state,
        ...initialState,
        isLoading: true
      };
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        loggedInUser: action.payload,
        isLoading: false,
        redirectTo: '/websites'
      };
    case actions.LOGIN_FAILED:
      return {
        ...state,
        errors: getActionError(action)
      };
    case actions.LOGOUT:
      return {
        ...initialState,
        loggedInUser: null
      };
    case actions.RESET_REDIRECT_TO:
      return {
        ...state,
        redirectTo: null
      }
    default:
      return state;
  }
}
