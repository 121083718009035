import React, { createContext, useReducer } from "react";
import * as actions from './actions/articleActions';
import { reducer, initialState } from "./reducers/articleReducer";

export const ArticleStateContext = createContext();
export const ArticleDispatchContext = createContext();

export const ArticlesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
        <ArticleStateContext.Provider value={state}>
            <ArticleDispatchContext.Provider value={{
              dispatch,
              actions
            }}
            >
                {children}
            </ArticleDispatchContext.Provider>
        </ArticleStateContext.Provider>
  );
}
