import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useAuthDispatch, useAuthState } from './hooks/useAuth';
import ProtectedRouter from './components/ProtectedRouter';
import Loading from './pages/Loading';
import MessageContainer from './components/MessageContainer';
import { Button, Container, Nav, Navbar, NavbarBrand, NavItem } from 'reactstrap';
import BreadcrumbsContainer from './components/BreadcrumbsContainer';
import WebsiteDetails from './pages/WebsiteDetails';
import { useRedirectIfUnauthorized } from './hooks/useRedirectIfUnauthorized'

const Login = lazy(async () => await import('./pages/Login'));
const WriteArticle = lazy(async () => await import('./pages/ArticleAdd'));
const Websites = lazy(async () => await import('./pages/Websites'));
const AddWebSite = lazy(async () => await import('./pages/WebSiteAdd'));
const EditWebSite = lazy(async () => await import('./pages/WebSiteEdit'));
const EditArticle = lazy(async () => await import('./pages/ArticleEdit'));

function App () {
  const { loggedInUser } = useAuthState();
  const { actions: { logout }, dispatch } = useAuthDispatch();

  useRedirectIfUnauthorized()

  return (
        <div>
            <Navbar color="dark">
                <Container>
                    <NavbarBrand href="/">iRevolution Group - Blog</NavbarBrand>
                    {loggedInUser !== null && (
                        <Nav className="mr-auto" navbar>
                            <NavItem>
                                <Button outline color='danger' onClick={() => logout()(dispatch)}>Logout</Button>
                            </NavItem>
                        </Nav>
                    )}
                </Container>
            </Navbar>

            {loggedInUser !== null && <BreadcrumbsContainer/>}

            <Container className="mb-5 pb-5">

                <Suspense fallback={Loading}>
                    <Switch>
                        <Route exact path="/">
                            {loggedInUser !== null
                              ? (
                                    <Redirect to="/websites"/>
                                )
                              : (
                                    <Redirect to="/login"/>
                                )}
                        </Route>

                        <Route exact path="/login">
                            <Login/>
                        </Route>

                        <ProtectedRouter exact path="/websites">
                            <Websites/>
                        </ProtectedRouter>

                        <ProtectedRouter exact path='/websites/:websiteUUID/articles/add'>
                            <WriteArticle/>
                        </ProtectedRouter>

                        <ProtectedRouter exact path='/websites/:websiteUUID/articles/:uuid/edit'>
                            <EditArticle/>
                        </ProtectedRouter>

                        <ProtectedRouter exact path="/websites/add/">
                            <AddWebSite/>
                        </ProtectedRouter>

                        <ProtectedRouter exact path="/websites/:uuid/edit">
                            <EditWebSite/>
                        </ProtectedRouter>

                        <ProtectedRouter exact path="/websites/:uuid">
                            <WebsiteDetails/>
                        </ProtectedRouter>

                        <Route>
                            <div>
                                <h1>404: Not found</h1>
                            </div>
                        </Route>
                    </Switch>
                </Suspense>
                <MessageContainer/>
            </Container>
        </div>
  );
}

export default App;
